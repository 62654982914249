import { ActionContext, Dispatch, Module } from 'vuex';
import { RootState } from '@/store';
import { createEmptyStatistics, createNewImpactAnalysis, ImpactAnalysis, ImpactAnalysisStatistics } from '@/features/impact-analysis/model';
import { createLoadingImpact, createNewImpact, ImpactDto, ImpactMap, NEW_IMPACT_ID } from '@/features/impacts/model';
import { Requirement, RequirementMap } from '@/features/sr-model/Requirement';
import { UiFeedback } from '@/store/ui-feedback';
import { impactService, LoadImpactAnalysisResponse } from '@/features/impacts/impact-service';
import { ImpactEntryCalculator } from '@/features/impacts/ImpactEntryCalculator';
import { LoadingType, safeVueSet, toMapWithValue } from '@/utils/util';
import { toFirstUpperCase } from '@/utils/StringUtils';
import { qmsRequirementService } from '@/services/qms-requirement-service';
import { QmsRequirementMap } from '@/store/regulation-detail';
import { SopGetters } from '@/features/sops';
import { Sop, SopSearchPart } from '@/model';
import { TemplateContentGetters } from '@/features/template-content/template-content-store';
import { SrRegulation } from '@/features/regulations/model';
import { RegulationDetail, srRegulationService } from '@/services/sr-regulation-service';
import { createEmptyQmsRequirement, QmsRequirement } from '@/services/model';
import { hasUserSrViewRights } from '@/utils/UserUtils';

/**
 *    from    *    to (new) *
 *            * 1.          * -> 0:1 (without existing impact)
 *   3        * 2.          * -> 3:1
 *   3a       *             *
 *   4        *             *
 *   5.       * 3.          * -> 2:2
 *   8.       * 4.          * -> 1:1
 */
export class ImpactUiEntry {
    uiId: string; // the impactId or the single toRequirementId, in case no impactId exists
    impactId?: string; // optional, since no each ImpactUiEntry has an equivalent entry on the backend
    toRequirementIds: string[]; // correct order
    fromRequirementIds: string[]; // correct order
}

export class ImpactsState {
    // loaded by IMPACT_LOAD
    impactAnalysis: ImpactAnalysis = createNewImpactAnalysis();
    statistics: ImpactAnalysisStatistics = createEmptyStatistics();
    impacts: ImpactMap = {};
    toRequirementIdToImpactId: { [key: string]: string } = {};
    fromRequirementIdToImpactId: { [key: string]: string } = {};

    fromQmsRequirements: QmsRequirementMap = {};
    toQmsRequirements: QmsRequirementMap = {};

    // loaded by s&r
    toRegulation: SrRegulation;
    toRegulationRequirements: RequirementMap = {};
    fromRegulation: SrRegulation;
    fromRegulationRequirements: RequirementMap = {};

    // calculated
    impactUiEntryLoadingState: 'LOADING' | 'COMPLETE' | 'INITIAL' = 'INITIAL';
    impactUiEntries: ImpactUiEntry[] = []; // correct order

    // impacts view
    editQmsRequirements = false;
    isImpactMode = false;

    // impact detail widget
    selectedImpact?: ImpactDto & { uiId?: string } = undefined;
    selectedImpactLoading: LoadingType = 'NOT_LOADED';

    editingRequirementId = '';
}

export enum ImpactsActions {
    IMPACT_LOAD = 'IMPACTS__IMPACT_LOAD',
    IMPACT_RELOAD = 'IMPACTS__IMPACT_RELOAD',
    SELECT_EMPTY_IMPACT_OF_TYPE_DELETED_REQUIREMENT = 'IMPACTS__SELECT_EMPTY_IMPACT_OF_TYPE_DELETED_REQUIREMENT',
    UPDATE_IMPACT = 'IMPACTS__UPDATE_IMPACT',
    DELETE_IMPACT = 'IMPACTS__DELETE_IMPACT',
    EDIT_UI_IMPACT = 'IMPACTS__EDIT_UI_IMPACT',
    CALCULATE_TO_REQUIREMENT_IMPACTS = 'IMPACTS__CALCULATE_TO_REQUIREMENT_IMPACTS',

    QMS_REQUIREMENTS_FROM_LOAD = 'IMPACTS__QMS_REQUIREMENTS_FROM_LOAD',
    QMS_REQUIREMENTS_TO_LOAD = 'IMPACTS__QMS_REQUIREMENTS_TO_LOAD',
    TO_QMS_REQUIREMENT_CHANGE = 'IMPACTS__QMS_REQUIREMENT_CHANGE',

    QMS_REQUIREMENT_CREATE = 'IMPACTS__QMS_REQUIREMENT_CREATE',
    QMS_REQUIREMENT_DELETE = 'IMPACTS__QMS_REQUIREMENT_DELETE',

    ADD_NEXT = 'IMPACTS__ADD_NEXT',
    ADD_BEFORE = 'IMPACTS__ADD_BEFORE',
    REMOVE_FIRST = 'IMPACTS__REMOVE_FIRST',
    REMOVE_LAST = 'IMPACTS__REMOVE_LAST',
    IMPACT_DETAIL_FIELDS_REPLACE = 'IMPACTS__IMPACT_DETAIL_FIELDS_REPLACE',
    IMPACT_RESET = 'IMPACTS__REST',

    IMPACT_MODE_LOAD = 'IMPACTS__IMPACT_MODE_LOAD',
    IMPACT_MODE_REPLACE = 'IMPACTS__IMPACT_MODE_REPLACE',
}

export enum ImpactsGetter {
    IMPACT_BY_IMPACT_ID_OR_LOADING = 'IMPACTS__IMPACT_BY_IMPACT_ID_OR_LOADING',
    TO_REQUIREMENTS_BY_IDS = 'IMPACTS__TO_REQUIREMENTS_BY_IDS',
    FROM_REQUIREMENTS_BY_IDS = 'IMPACTS__FROM_REQUIREMENTS_BY_IDS',
    TO_QMS_REQUIREMENT_BY_REQUIREMENT_ID = 'IMPACTS__TO_QMS_REQUIREMENT_BY_REQUIREMENT_ID',
    FROM_QMS_REQUIREMENT_BY_REQUIREMENT_ID = 'IMPACTS__FROM_QMS_REQUIREMENT_BY_REQUIREMENT_ID',

    PROPOSED_SOPS_BY_IMPACT_ID = 'IMPACTS__PROPOSED_SOPS_BY_IMPACT_ID',
    PROPOSED_TEMPLATE_CONTENTS_BY_IMPACT_ID = 'IMPACTS__PROPOSED_TEMPLATE_CONTENTS_BY_IMPACT_ID',

    UI_IMPACTS_WITHOUT_TO_REQUIREMENTS = 'UI_IMPACTS_WITHOUT_TO_REQUIREMENTS',
    HAS_VIEW_RIGHTS = 'IMPACTS__HAS_VIEW_RIGHTS',
}

export enum ImpactsMutations {
    TOGGLE_QMS_REQUIREMENTS_EDITABLE = 'IMPACTS__IMPACTS_TOGGLE_QMS_REQUIREMENTS_EDITABLE',
    IMPACT_MODE_REPLACE = 'IMPACT_MODE_REPLACE',
    EDITING_REQUIREMENT_ID = 'IMPACTS__IMPACTS_EDITING_REQUIREMENT_ID',
}

enum Mutations {
    IMPACT_LOAD_REPLACE = 'IMPACTS__IMPACT_LOAD_REPLACE',
    IMPACT_REGULATION_REPLACE = 'IMPACTS__IMPACT_REGULATION_REPLACE',

    FROM_QMS_REQUIREMENTS_REPLACE = 'IMPACTS__FROM_QMS_REQUIREMENTS_REPLACE',
    TO_QMS_REQUIREMENTS_REPLACE = 'IMPACTS__TO_QMS_REQUIREMENTS_REPLACE',
    TO_QMS_REQUIREMENT_REPLACE = 'IMPACTS__TO_QMS_REQUIREMENT_REPLACE',

    IMPACT_UI_ENTRIES_REPLACE = 'IMPACTS__IMPACT_UI_ENTRIES_REPLACE',
    START_LOADING_IMPACT_UI_ENTRIES = 'IMPACTS__START_LOADING_IMPACT_UI_ENTRIES',
    COMPLETE_LOADING_IMPACT_UI_ENTRIES = 'IMPACTS__COMPLETE_LOADING_IMPACT_UI_ENTRIES',
    SELECTED_IMPACT_REPLACE = 'IMPACTS__SELECTED_IMPACT_REPLACE',
    SELECTED_IMPACT_LOADING_REPLACE = 'SELECTED_IMPACT_LOADING_REPLACE',
    IMPACT_FIELD_REPLACE = 'IMPACTS__IMPACT_FIELD_REPLACE',
    RESET = 'IMPACTS__RESET',
}

export const createRequirementIdSorter = (requirementMap: RequirementMap) => {
    const requirementKeys = Object.keys(requirementMap);
    return (reqIdA: string, reqIdB: string) => requirementKeys.indexOf(reqIdA) - requirementKeys.indexOf(reqIdB);
}

function createRequirementSorter(requirementMap: RequirementMap) {
    const requirementKeys = Object.keys(requirementMap);
    return (reqA: Requirement, reqB: Requirement) => requirementKeys.indexOf(reqA.id) - requirementKeys.indexOf(reqB.id);
}

const IMPACT_MODE_KEY = 'impact-analysis.is-impact-mode';


const getters = {
    [ImpactsGetter.HAS_VIEW_RIGHTS]: (state: ImpactsState, getters: any, rootState: RootState): boolean =>
        hasUserSrViewRights(state.fromRegulation?.licenseTypeView, rootState.auth.user)
        && hasUserSrViewRights(state.toRegulation?.licenseTypeView, rootState.auth.user),
    [ImpactsGetter.IMPACT_BY_IMPACT_ID_OR_LOADING]: (state: ImpactsState) => (impactId: string): ImpactDto =>
        state.impacts[impactId] || createLoadingImpact(impactId),
    [ImpactsGetter.TO_REQUIREMENTS_BY_IDS]: (state: ImpactsState) => (requirementIds: string[]): Requirement[] =>
        requirementIds.map(requirementId => state.toRegulationRequirements[requirementId])
            .filter(req => !!req)
            .sort(createRequirementSorter(state.toRegulationRequirements)),
    [ImpactsGetter.FROM_REQUIREMENTS_BY_IDS]: (state: ImpactsState) => (requirementIds: string[]): Requirement[] =>
        requirementIds.map(requirementId => state.fromRegulationRequirements[requirementId])
            .filter(req => !!req)
            .sort(createRequirementSorter(state.fromRegulationRequirements)),
    [ImpactsGetter.TO_QMS_REQUIREMENT_BY_REQUIREMENT_ID]: (state: ImpactsState) => (requirementId: string): QmsRequirement =>
        state.toQmsRequirements[requirementId] || { ...createEmptyQmsRequirement(), requirementId, regulationId: state.toRegulation.id },
    [ImpactsGetter.FROM_QMS_REQUIREMENT_BY_REQUIREMENT_ID]: (state: ImpactsState) => (requirementId: string): QmsRequirement =>
        state.fromQmsRequirements[requirementId] || { ...createEmptyQmsRequirement(), requirementId, regulationId: state.fromRegulation.id },
    [ImpactsGetter.PROPOSED_SOPS_BY_IMPACT_ID]: (state: ImpactsState, getters: any, rootState: RootState, rootGetters: any) => (impactId: string): SopSearchPart[] => {
        const impact = state.impacts[impactId];
        if (!impact) {
            return [];
        }
        const allSopArtifactIds = impact.requirementIdsFrom
            .map(reqId => state.fromQmsRequirements[reqId])
            .filter(qmsReq => !!qmsReq)
            .flatMap(qmsReq => qmsReq.targetSopArtifactIds);
        return [...new Set(allSopArtifactIds)]
            .map(sopArtifactId => rootGetters[SopGetters.SOP_BY_ARTIFACT_ID](sopArtifactId))
    },
    [ImpactsGetter.PROPOSED_TEMPLATE_CONTENTS_BY_IMPACT_ID]: (state: ImpactsState, getters: any, rootState: RootState, rootGetters: any) => (impactId: string): Sop[] => {
        const impact = state.impacts[impactId];
        if (!impact) {
            return [];
        }
        const allTemplateContentIds = impact.requirementIdsFrom
            .map(reqId => state.fromQmsRequirements[reqId])
            .filter(qmsReq => !!qmsReq)
            .flatMap(qmsReq => qmsReq.templateContentIds);
        return [...new Set(allTemplateContentIds)]
            .map(templateContentId => rootGetters[TemplateContentGetters.TEMPLATE_CONTENT_BY_ID_OR_EMPTY](templateContentId))
    },
    [ImpactsGetter.UI_IMPACTS_WITHOUT_TO_REQUIREMENTS]: (state: ImpactsState) =>
        state.impactUiEntries.filter(uiImpact => uiImpact.toRequirementIds.length === 0),
}

function removeFirstOrLast(state: ImpactsState, getters: any, dispatch: Dispatch, isFirst = true) {
    if (!state.selectedImpact) {
        return UiFeedback.showError(dispatch, 'No impact selected', new Error());
    }
    if (state.selectedImpact.requirementIdsTo.length <= 1) {
        return UiFeedback.showError(dispatch, 'One requirement is required.', new Error());
    }
    const toRequirementsInCorrectOrder = getters[ImpactsGetter.TO_REQUIREMENTS_BY_IDS](state.selectedImpact.requirementIdsTo);
    const requirementIdToRemove: string = isFirst ? toRequirementsInCorrectOrder[0].id : toRequirementsInCorrectOrder[toRequirementsInCorrectOrder.length - 1].id;
    const newImpact = {
        ...state.selectedImpact,
        requirementIdsTo: state.selectedImpact.requirementIdsTo.filter(id => id !== requirementIdToRemove),
    }
    return dispatch(ImpactsActions.UPDATE_IMPACT, newImpact);
}

function addNextOrBefore(state: ImpactsState, getters: any, dispatch: Dispatch, isNext = true) {
    if (!state.selectedImpact) {
        return UiFeedback.showError(dispatch, 'No impact selected', new Error());
    }
    const toRequirementsInCorrectOrder = getters[ImpactsGetter.TO_REQUIREMENTS_BY_IDS](state.selectedImpact.requirementIdsTo);

    function getNext() {
        const lastRequirementId = toRequirementsInCorrectOrder[toRequirementsInCorrectOrder.length - 1].id;
        const toRegulationIds = Object.keys(state.toRegulationRequirements);
        const indexToAdd = toRegulationIds.indexOf(lastRequirementId) + 1;
        return { toRegulationIds, indexToAdd };
    }

    function getBefore() {
        const lastRequirementId = toRequirementsInCorrectOrder[0].id;
        const toRegulationIds = Object.keys(state.toRegulationRequirements);
        const indexToAdd = toRegulationIds.indexOf(lastRequirementId) - 1;
        return { toRegulationIds, indexToAdd };
    }

    const { toRegulationIds, indexToAdd } = isNext ? getNext() : getBefore();
    const requirementIdToAdd = toRegulationIds[indexToAdd];
    if (!requirementIdToAdd) {
        return UiFeedback.showError(dispatch, 'Can\'t find next requirement...', new Error())
    }
    if (state.toRequirementIdToImpactId[requirementIdToAdd]) {
        return UiFeedback.showError(dispatch, 'Next requirement is already mapped with an impact', new Error())
    }
    const newImpact = {
        ...state.selectedImpact,
        requirementIdsTo: [
            ...state.selectedImpact.requirementIdsTo,
            requirementIdToAdd
        ]
    }
    return dispatch(ImpactsActions.UPDATE_IMPACT, newImpact);
}

function setterName(field: string) {
    return 'set' + toFirstUpperCase(field);
}

export function setterForImpactFields(...fields: Array<keyof ImpactDto>) {
    const setImpactField = (dispatch: Dispatch, field: Partial<ImpactDto>): Promise<void> =>
        dispatch(ImpactsActions.IMPACT_DETAIL_FIELDS_REPLACE, field);
    const setterMethods: any = {};
    fields.forEach(field => {
        setterMethods[setterName(field)] = (dispatch: Dispatch, value: string) => setImpactField(dispatch, { [field]: value })
    });
    return setterMethods;
}

const actions = {
    [ImpactsActions.QMS_REQUIREMENTS_FROM_LOAD]: ({ state, commit }: ActionContext<ImpactsState, RootState>) => {
        return qmsRequirementService.loadQmsRequirementsForRegulation(state.fromRegulation.id)
            .then(qmsRequirements => commit(Mutations.FROM_QMS_REQUIREMENTS_REPLACE, qmsRequirements))
    },
    [ImpactsActions.QMS_REQUIREMENTS_TO_LOAD]: ({ state, commit }: ActionContext<ImpactsState, RootState>) => {
        return qmsRequirementService.loadQmsRequirementsForRegulation(state.toRegulation.id)
            .then(qmsRequirements => commit(Mutations.TO_QMS_REQUIREMENTS_REPLACE, qmsRequirements))
    },
    [ImpactsActions.TO_QMS_REQUIREMENT_CHANGE]: ({ commit, dispatch }: ActionContext<ImpactsState, RootState>, qmsRequirement: QmsRequirement) =>
        qmsRequirementService.changeQmsRequirement(qmsRequirement)
            .then(qmsRequirement => commit(Mutations.TO_QMS_REQUIREMENT_REPLACE, qmsRequirement))
            .catch(err => UiFeedback.showError(dispatch, `QmsRequirement entry for requirement id ${ qmsRequirement.requirementId } couldn't be updated.`, err)),

    [ImpactsActions.QMS_REQUIREMENT_CREATE]: ({ state, dispatch }: ActionContext<ImpactsState, RootState>, srRequirementId: string) => {
        const emptyQmsRequirement = createEmptyQmsRequirement({
            regulationId: state.toRegulation.id,
            requirementId: srRequirementId,
        });
        return dispatch(ImpactsActions.TO_QMS_REQUIREMENT_CHANGE, emptyQmsRequirement)
            .then(() => dispatch(ImpactsActions.QMS_REQUIREMENTS_TO_LOAD));
    },
    [ImpactsActions.QMS_REQUIREMENT_DELETE]: ({ dispatch, state }: ActionContext<ImpactsState, RootState>, requirementId: string) => {
        return qmsRequirementService.deleteQmsRequirement(requirementId)
            .then(() => dispatch(ImpactsActions.QMS_REQUIREMENTS_TO_LOAD));
    },
    [ImpactsActions.IMPACT_DETAIL_FIELDS_REPLACE]: ({ commit }: ActionContext<ImpactsState, RootState>, fields: Partial<ImpactDto>) => {
        commit(Mutations.IMPACT_FIELD_REPLACE, fields);
        return Promise.resolve();
    },
    [ImpactsActions.IMPACT_LOAD]: ({ commit, dispatch }: ActionContext<ImpactsState, RootState>, impactAnalysisId: string) => {
        commit(Mutations.START_LOADING_IMPACT_UI_ENTRIES);
        return impactService.loadImpactAnalysis(impactAnalysisId)
            .then(impactAnalyses => {
                commit(Mutations.IMPACT_LOAD_REPLACE, impactAnalyses);
                return Promise.all([
                    srRegulationService.loadRegulationDetail(impactAnalyses.impactAnalysis.regulationIdFrom),
                    srRegulationService.loadRegulationDetail(impactAnalyses.impactAnalysis.regulationIdTo)
                ])
                    .then(fromAndToRegulation => {
                        commit(Mutations.IMPACT_REGULATION_REPLACE, fromAndToRegulation);
                        return Promise.all([
                            dispatch(ImpactsActions.QMS_REQUIREMENTS_FROM_LOAD)
                                .catch(err => UiFeedback.showError(dispatch, `Can't load from-qms-requirements, try again later.`, err)),
                            dispatch(ImpactsActions.QMS_REQUIREMENTS_TO_LOAD)
                                .catch(err => UiFeedback.showError(dispatch, `Can't load to-qms-requirements, try again later.`, err))
                            // Load relations here
                        ]);
                    })
                    .then(() => dispatch(ImpactsActions.CALCULATE_TO_REQUIREMENT_IMPACTS))
                    .catch(err => UiFeedback.showError(dispatch, `Regulation FROM/TO for impactAnalysis ${ impactAnalyses.impactAnalysis.id } couldn't be fetched.`, err))
                    .finally(() => commit(Mutations.COMPLETE_LOADING_IMPACT_UI_ENTRIES));
            })
            .catch(err => UiFeedback.showError(dispatch, 'Impact Analysis could not be loaded, try again later.', err));
    },
    [ImpactsActions.IMPACT_RELOAD]: ({ commit, dispatch, state }: ActionContext<ImpactsState, RootState>) => {
        return impactService.loadImpactAnalysis(state.impactAnalysis.id)
            .then(impactAnalyses => {
                commit(Mutations.IMPACT_LOAD_REPLACE, impactAnalyses);
                return dispatch(ImpactsActions.CALCULATE_TO_REQUIREMENT_IMPACTS);
            })
            .catch(err => UiFeedback.showError(dispatch, 'Impact Analysis could not be loaded, try again later.', err));
    },
    [ImpactsActions.CALCULATE_TO_REQUIREMENT_IMPACTS]: ({ state, commit }: ActionContext<ImpactsState, RootState>) => {
        const impactUiEntries = new ImpactEntryCalculator(
            Object.keys(state.toRegulationRequirements), state.toRequirementIdToImpactId,
            Object.keys(state.fromRegulationRequirements), state.fromRequirementIdToImpactId,
            state.impacts)
            .calculateImpactEntry();
        return new Promise(resolve => {
            commit(Mutations.IMPACT_UI_ENTRIES_REPLACE, impactUiEntries);
            resolve();
        });
    },
    [ImpactsActions.SELECT_EMPTY_IMPACT_OF_TYPE_DELETED_REQUIREMENT]: ({ commit, state }: ActionContext<ImpactsState, RootState>) => {
        const selectedImpact = createNewImpact(state.impactAnalysis.id, { impactType: 'NOT_DEFINED' });
        return commit(Mutations.SELECTED_IMPACT_REPLACE, selectedImpact);
    },
    [ImpactsActions.EDIT_UI_IMPACT]: ({ state, commit }: ActionContext<ImpactsState, RootState>, uiImpact: ImpactUiEntry) => {
        commit(Mutations.SELECTED_IMPACT_LOADING_REPLACE, 'LOADING');
        const setSelectedImpact = (selectedImpact?: ImpactDto & { uiId?: string }, selectedImpactLoading: LoadingType = 'COMPLETED') => {
            commit(Mutations.SELECTED_IMPACT_REPLACE, selectedImpact);
            commit(Mutations.SELECTED_IMPACT_LOADING_REPLACE, selectedImpactLoading);
        };
        setTimeout(() => {
            if (!uiImpact) {
                setSelectedImpact(undefined, 'NOT_LOADED');
            } else if (uiImpact.impactId) {
                setSelectedImpact(state.impacts[uiImpact.impactId]);
            } else {
                const newImpact = createNewImpact(state.impactAnalysis.id, { requirementIdsTo: [...uiImpact.toRequirementIds] });
                setSelectedImpact({ ...newImpact, uiId: uiImpact.uiId });
            }
        });
        return Promise.resolve();
    },
    [ImpactsActions.UPDATE_IMPACT]: ({ commit, dispatch }: ActionContext<ImpactsState, RootState>, impact: ImpactDto) => {
        const impactAnalysisPromise = impact.id === NEW_IMPACT_ID ?
            impactService.createImpact(impact) :
            impactService.updateImpact(impact);
        return impactAnalysisPromise
            .then(impact => commit(Mutations.SELECTED_IMPACT_REPLACE, impact))
            .catch(error => UiFeedback.showAndThrowError(dispatch, 'Update of impact failed', error))
            .finally(() => dispatch(ImpactsActions.IMPACT_RELOAD));
    },
    [ImpactsActions.DELETE_IMPACT]: ({ commit, dispatch }: ActionContext<ImpactsState, RootState>, impact: ImpactDto) => {
        if (impact.id === NEW_IMPACT_ID) {
            commit(Mutations.SELECTED_IMPACT_REPLACE, undefined);
            return Promise.resolve();
        }
        impactService.deleteImpact(impact.impactAnalysisId, impact.id)
            .then(() => commit(Mutations.SELECTED_IMPACT_REPLACE, undefined))
            .catch(error => UiFeedback.showAndThrowError(dispatch, 'Delete of impact failed', error))
            .finally(() => dispatch(ImpactsActions.IMPACT_RELOAD));
    },
    [ImpactsActions.ADD_NEXT]: ({ state, dispatch, getters }: ActionContext<ImpactsState, RootState>) => {
        return addNextOrBefore(state, getters, dispatch, true);
    },
    [ImpactsActions.ADD_BEFORE]: ({ state, dispatch, getters }: ActionContext<ImpactsState, RootState>) => {
        return addNextOrBefore(state, getters, dispatch, false);
    },
    [ImpactsActions.REMOVE_FIRST]: ({ state, dispatch, getters }: ActionContext<ImpactsState, RootState>) => {
        return removeFirstOrLast(state, getters, dispatch, true);
    },
    [ImpactsActions.REMOVE_LAST]: ({ state, dispatch, getters }: ActionContext<ImpactsState, RootState>) => {
        return removeFirstOrLast(state, getters, dispatch, false);
    },
    [ImpactsActions.IMPACT_RESET]: ({ commit }: ActionContext<ImpactsState, RootState>) => commit(Mutations.RESET),
    [ImpactsActions.IMPACT_MODE_REPLACE]: ({ commit }: ActionContext<ImpactsState, RootState>, isImpactMode: boolean) => commit(ImpactsMutations.IMPACT_MODE_REPLACE, isImpactMode),
    [ImpactsActions.IMPACT_MODE_LOAD]: ({ commit }: ActionContext<ImpactsState, RootState>) => {
        const isImpactModeAsString = localStorage.getItem(IMPACT_MODE_KEY);
        if (!isImpactModeAsString) {
            return;
        }
        commit(ImpactsMutations.IMPACT_MODE_REPLACE, isImpactModeAsString.toLocaleLowerCase() === 'true');
    }
};

const mutations = {
    [ImpactsMutations.TOGGLE_QMS_REQUIREMENTS_EDITABLE]: (state: ImpactsState) => {
        safeVueSet(state, 'editQmsRequirements', !state.editQmsRequirements);
    },
    [ImpactsMutations.IMPACT_MODE_REPLACE]: (state: ImpactsState, isImpactMode: boolean) => {
        localStorage.setItem(IMPACT_MODE_KEY, isImpactMode.toString());
        safeVueSet(state, 'isImpactMode', isImpactMode)
    },
    [ImpactsMutations.EDITING_REQUIREMENT_ID]: (state: ImpactsState, requirementId: string) => {
        if (state.editingRequirementId === requirementId) {
            requirementId = '';
        }
        safeVueSet(state, 'editingRequirementId', requirementId || '');
    },
    [Mutations.IMPACT_LOAD_REPLACE]: (state: ImpactsState, loadImpactAnalysisResponse: LoadImpactAnalysisResponse) => {
        safeVueSet(state, 'impactAnalysis', loadImpactAnalysisResponse.impactAnalysis);
        safeVueSet(state, 'statistics', loadImpactAnalysisResponse.statistics);
        safeVueSet(state, 'impacts', loadImpactAnalysisResponse.impacts);

        const toReqMap = (x: any) => toMapWithValue(x, (x: any) => x.reqId, (x: any) => x.impactId)

        const toRequirementIdToImpactId = Object.values(loadImpactAnalysisResponse.impacts)
            .flatMap(impact => impact.requirementIdsTo.map(reqId => ({ reqId, impactId: impact.id })));
        safeVueSet(state, 'toRequirementIdToImpactId', toReqMap(toRequirementIdToImpactId));

        const fromRequirementIdToImpactId = Object.values(loadImpactAnalysisResponse.impacts)
            .flatMap(impact => impact.requirementIdsFrom.map(reqId => ({ reqId, impactId: impact.id })));
        safeVueSet(state, 'fromRequirementIdToImpactId', toReqMap(fromRequirementIdToImpactId));
    },
    [Mutations.IMPACT_REGULATION_REPLACE]: (state: ImpactsState, fromAndToRegulation: RegulationDetail[]) => {
        safeVueSet(state, 'fromRegulation', fromAndToRegulation[0].regulation);
        safeVueSet(state, 'fromRegulationRequirements', fromAndToRegulation[0].requirements);

        safeVueSet(state, 'toRegulation', fromAndToRegulation[1].regulation);
        safeVueSet(state, 'toRegulationRequirements', fromAndToRegulation[1].requirements);
    },
    [Mutations.FROM_QMS_REQUIREMENTS_REPLACE]: (state: ImpactsState, fromQmsRequirements: QmsRequirementMap) =>
        safeVueSet(state, 'fromQmsRequirements', fromQmsRequirements),
    [Mutations.TO_QMS_REQUIREMENTS_REPLACE]: (state: ImpactsState, toQmsRequirements: QmsRequirementMap) =>
        safeVueSet(state, 'toQmsRequirements', toQmsRequirements),
    [Mutations.TO_QMS_REQUIREMENT_REPLACE]: (state: ImpactsState, qmsRequirement: QmsRequirement) =>
        safeVueSet(state.toQmsRequirements, qmsRequirement.requirementId, qmsRequirement),
    [Mutations.IMPACT_UI_ENTRIES_REPLACE]: (state: ImpactsState, impactUiEntries: ImpactUiEntry[]) =>
        safeVueSet(state, 'impactUiEntries', impactUiEntries),
    [Mutations.START_LOADING_IMPACT_UI_ENTRIES]: (state: ImpactsState) => safeVueSet(state, 'impactUiEntryLoadingState', 'LOADING'),
    [Mutations.COMPLETE_LOADING_IMPACT_UI_ENTRIES]: (state: ImpactsState) => safeVueSet(state, 'impactUiEntryLoadingState', 'COMPLETE'),
    [Mutations.SELECTED_IMPACT_REPLACE]: (state: ImpactsState, selectedImpact: ImpactDto) => {
        if (selectedImpact && selectedImpact.id !== NEW_IMPACT_ID && !state.impacts[selectedImpact.id]) {
            safeVueSet(state.impacts, selectedImpact.id, selectedImpact);
        }
        safeVueSet(state, 'selectedImpact', selectedImpact);
    },
    [Mutations.SELECTED_IMPACT_LOADING_REPLACE]: (state: ImpactsState, selectedImpactLoading: LoadingType) =>
        safeVueSet(state, 'selectedImpactLoading', selectedImpactLoading),
    [Mutations.IMPACT_FIELD_REPLACE]: (state: ImpactsState, impact: Partial<ImpactDto>) => {
        if (!state.selectedImpact) {
            safeVueSet(state, 'selectedImpact', {});
        }
        Object.assign(state.selectedImpact, impact);
    },
    [Mutations.RESET]: (state: ImpactsState) => Object.assign(state, new ImpactsState()),
};

export const IMPACTS_MODULE: Module<ImpactsState, RootState> = {
    state: new ImpactsState(),
    getters,
    actions,
    mutations
};